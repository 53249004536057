<template>
  <b-card class="login-body mb-5 mt-8">
    <h1>Behandling av Personuppgifter - Memlist</h1>

    <h2>1. Inledning</h2>
    <p>
        Detta dokument beskriver hur personuppgifter samlas in, används och hanteras på webbtjänsten "Memlist" (nedan kallad "Webbtjänsten"). Memlist strävar efter att skydda användares integritet och följer alla gällande lagar och föreskrifter för att säkerställa att personuppgifter behandlas på ett säkert och ansvarsfullt sätt.
    </p>

    <h2>2. Vilka personuppgifter samlas in</h2>
    <p>
        När du registrerar dig som användare på Memlist kan följande personuppgifter samlas in:
    </p>
    <ul>
        <li>Namn</li>
        <li>E-postadress</li>
        <li>Användarnamn och lösenord</li>
        <li>Eventuella föreningsuppgifter och kontaktuppgifter</li>
        <li>Annat innehåll som du väljer att ladda upp eller dela på Webbtjänsten</li>
    </ul>

    <h2>3. Varför samlas personuppgifter in</h2>
    <p>
        Personuppgifter samlas in för följande ändamål:
    </p>
    <ul>
        <li>För att skapa och hantera ditt användarkonto på Memlist</li>
        <li>För att ge dig tillgång till Webbtjänstens funktioner och tjänster</li>
        <li>För att kommunicera med dig och tillhandahålla support</li>
        <li>För att anpassa och förbättra din användarupplevelse på Webbtjänsten</li>
        <li>För att uppfylla lagliga skyldigheter och krav</li>
    </ul>

    <h2>4. Dataskydd och Säkerhet</h2>
    <p>
        Memlist vidtar tekniska och organisatoriska åtgärder för att säkerställa att personuppgifter behandlas på ett säkert sätt och för att skydda mot obehörig åtkomst, förlust eller otillbörlig användning. Endast auktoriserad personal har tillgång till personuppgifterna och de kommer att behandlas i enlighet med denna integritetspolicy och gällande lagstiftning.
    </p>

    <h2>5. Delning av personuppgifter</h2>
    <p>
        Memlist delar inte personuppgifter med tredje part utan ditt samtycke, förutom i de fall där det krävs enligt lag eller vid bedrägeriundersökningar, rättsliga förfaranden eller för att skydda våra rättigheter och egendom.
    </p>

    <h2>6. Dina rättigheter</h2>
    <p>
        Du har rätt att begära tillgång till, korrigering eller radering av dina personuppgifter som vi har registrerat. Om du önskar utöva någon av dessa rättigheter, vänligen kontakta oss på support@memlist.se. Vi kommer att svara på din begäran inom skälig tid och i enlighet med gällande lagstiftning.
    </p>

    <h2>7. Ändringar i Integritetspolicyn</h2>
    <p>
        Memlist förbehåller sig rätten att uppdatera denna Integritetspolicy vid behov. Eventuella ändringar kommer att publiceras på denna webbsida och du bör regelbundet granska Integritetspolicyn för att vara medveten om eventuella förändringar.
    </p>

    <h2>8. Kontakta oss</h2>
    <p>
        Om du har några frågor eller funderingar gällande behandlingen av personuppgifter på Memlist, vänligen kontakta oss på support@memlist.se.
    </p>
  </b-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapState } from 'vuex';

import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  name: 'privacy-policy',
  data() {
    return {
     
    };
  },
  
  mounted() {

  },
  methods: {
    
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
